import { EnumsValues } from '../enums/EnumsValues';

export const formatValue = (
  value: number | undefined | null,
  digits: number = EnumsValues.ConstNumbers.two,
) => {
  if (value === undefined || value === null) return '0,00';
  return value.toLocaleString('es-AR', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};
