export const REVIEWER_CONFIGURATION_TYPE_OPTIONS = `
query reviewerConfigurationTypeOptions(
  $filter: FilterReviewerConfigurationTypeOptionInput,
    $orderBy:  ReviewerConfigurationTypeOptionOrderInput,
    $searchText: String,
    $skip: Int,
    $take: Int
  ){
    reviewerConfigurationTypeOptions(
      filter: $filter
      orderBy: $orderBy
      searchText:$searchText      
      skip: $skip
      take: $take
    ) {
      __typename
      ... on ReviewerConfigurationTypeOption{
        id
        code
        name
        translation_key
        reviewer_configuration_type_option_status_id
          }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;

export const GET_REVIEWER_CONFIGURATION_TYPE_BY_DOCUMENT_TYPE_ID = `
query getReviewerConfigurationTypeByDocumentTypeId(
  $input: ReviewerConfigurationTypeInformationInput!
  ){
    getReviewerConfigurationTypeByDocumentTypeId(
      input: $input
    ) {
      __typename
      ... on ReviewerConfigurationType{
        id
        reviewer_configuration_type_option_id
        
        reviewer_configuration_type_tenant {
          id
          reviewer_configuration_type_option_id
        }
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;
