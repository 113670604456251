import { DatePicker, Input } from 'antd';
import moment from 'moment';
import { ReceiptsQuotaTenantPartial } from '../../../interfaces/ReceiptQuotaTenant';
import './CreditsSection.less';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';

interface Props {
  receiptQuotaTenant: ReceiptsQuotaTenantPartial | undefined;
  onCreditsChange: (key: string, value: any) => void;
}

enum Keys {
  receipts_quota = 'receipts_quota',
  quota_expiration_date = 'quota_expiration_date',
}

const CreditsSection = (props: Props) => {
  const { receiptQuotaTenant, onCreditsChange } = props;

  const { t } = useContext(ContextApp);

  return (
    <div className="credits-section">
      <div className="credits-section__content">
        {receiptQuotaTenant && (
          <>
            <div className="credits-section__content__container">
              <p className="credits-section__content__container__label">
                {t('tenantEditAdmin.creditsSection.availableCredits')}
              </p>
              <Input
                type="number"
                id="receipt_quota"
                aria-label="receiptQuota"
                value={receiptQuotaTenant.receipts_quota}
                onChange={(e) =>
                  onCreditsChange(Keys.receipts_quota, Number(e.target.value))
                }
              />
            </div>
            <div className="credits-section__content__container">
              <p className="credits-section__content__container__label">
                {t('tenantEditAdmin.creditsSection.creditsExpirationDate')}
              </p>
              <DatePicker
                id="quota_expiration_date"
                aria-label="quotaExpirationDate"
                value={moment(receiptQuotaTenant.quota_expiration_date)}
                onChange={(date) =>
                  onCreditsChange(Keys.quota_expiration_date, date?.toDate())
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreditsSection;
