import { IAppSetting } from '../interfaces';
import GraphqlService from '../services/graphql/GraphqlService';

const useAppSettingService = () => {
  const { Query, customRequest } = GraphqlService();

  const getAppSetting = async (key: string): Promise<IAppSetting> => {
    return customRequest({
      query: Query.getAppSettingByKey,
      variables: {
        input: { key },
      },
    });
  };

  return {
    getAppSetting,
  };
};

export default useAppSettingService;
