export const GET_ALL_FEATURE_FLAG_CONFIG = `
query getAllFeatureFlagsConfig {
    getAllFeatureFlagsConfig {
      __typename
      ... on FullFeatureFlagConfiguration {
        defaultAvailable
        defaultHidden
        featureFlagConfiguration {
          feature_flag{
            id
            name
            code
            description
            translation_key_name
            translation_key_description
            is_public
          }
          available
          hidden
        }
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }`;

export const GET_ALL_PUBLIC_FEATURE_FLAG_CONFIG = `
query getAllPublicFeatureFlagsConfig {
    getAllPublicFeatureFlagsConfig {
      __typename
      ... on FullFeatureFlagConfiguration {
        defaultAvailable
        defaultHidden
        featureFlagConfiguration {
          feature_flag{
            id
            name
            code
            description
            translation_key_name
            translation_key_description
            is_public
            readonly
          }
          available
          hidden
        }
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }`;

export const GET_ONE_FEATURE_FLAG_CONFIG_VALUE = `
query getOneFeatureFlagConfigValue($feature_flag_code: String!, $tenant_id: Int) {
    getOneFeatureFlagConfigValue(feature_flag_code: $feature_flag_code, tenant_id: $tenant_id) {
      __typename
      ... on FeatureFlagConfigGeneralValue {
        available
        hidden
        feature_flag_id
        feature_flag{
        id
        name
        code
        description
        translation_key_name
        translation_key_description
        is_public
        readonly
         }
        }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }`;

export const GET_ONE_PUBLIC_FEATURE_FLAG_CONFIG_VALUE = `
  query getOnePublicFeatureFlagConfigValue($feature_flag_code: String!) {
      getOnePublicFeatureFlagConfigValue(feature_flag_code: $feature_flag_code) {
        __typename
        ... on FeatureFlagConfigGeneralValue {
          available
          hidden
          feature_flag_id
          feature_flag{
          id
          name
          code
          description
          translation_key_name
          translation_key_description
          is_public
          readonly
            }
          }
        ... on ResultError {
          status_code
          message
          message_translation_key
        }
      }
    }`;

export const GET_ALL_FEATURE_FLAGS_CONFIG_FOR_TENANT_ID = `
  query getAllFeatureFlagsConfigForTenantId($tenant_id: Int)
   {
      getAllFeatureFlagsConfigForTenantId(tenant_id: $tenant_id) {
        __typename
        ... on FullFeatureFlagConfiguration {
          defaultAvailable
          defaultHidden
          featureFlagConfiguration {
            feature_flag_id
            feature_flag{
              id
              name
              code
              description
              translation_key_name
              translation_key_description
              is_public
            readonly
            }
            available
            hidden
          }
        }
        ... on ResultError {
          status_code
          message
          message_translation_key
        }
      }
    }`;

export const GET_DEFAULT_FEATURE_FLAGS_CONFIG = `
  query getDefaultFeatureFlagsConfig
   {
      getDefaultFeatureFlagsConfig {
        __typename
        ... on FeatureFlagConfigGeneralValue {
          available
          hidden
          feature_flag_id
          feature_flag{
          id
          name
          code
          description
          translation_key_name
          translation_key_description
          is_public
          readonly
           }
          }
        ... on ResultError {
          status_code
          message
          message_translation_key
        }
      }
    }`;
