import { IReceiptType } from '../interfaces/Receipt';
import { UpdateReceiptsQuotaTenantInput } from '../interfaces/ReceiptQuotaTenant';
import GraphqlService from '../services/graphql/GraphqlService';

const useReceiptQuotaService = () => {
  const { Query, customRequest, Mutation } = GraphqlService();

  const getReceiptQuotaTenant = async (params: {
    tenant_id: number;
  }): Promise<IReceiptType[]> => {
    const { tenant_id } = params;
    return customRequest({
      query: Query.getReceiptQuotaTenant,
      variables: {
        filter: {
          tenant_id,
        },
      },
    });
  };

  const updateReceiptsQuotaTenant = async (
    params: UpdateReceiptsQuotaTenantInput,
  ) => {
    const { tenant_id, quota_expiration_date, receipts_quota } = params;
    return customRequest({
      query: Mutation.updateReceiptsQuotaTenant,
      variables: {
        input: {
          tenant_id,
          quota_expiration_date,
          receipts_quota,
        },
      },
    });
  };

  return {
    getReceiptQuotaTenant,
    updateReceiptsQuotaTenant,
  };
};

export default useReceiptQuotaService;
