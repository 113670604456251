import { Modal } from 'antd';
import { useContext } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import ButtonTertiary from '../../components/common/Buttons/ButtonTertiary';
import ButtonSecondary from '../../components/common/Buttons/ButtonSecondary';
import ButtonAction from '../../components/common/Buttons/ButtonAction';
import './FunctionalitiesRestoreModal.less';
import { IFeatureFlagConfigGeneralValue } from '../../interfaces/FeatureFlag';

interface FunctionalitiesRestoreModalProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onRestoreToPreviousState: () => void;
  onRestoreToDefault: () => void;
  currentSetting: IFeatureFlagConfigGeneralValue[];
  currentFeatureId: number | null;
}

const FunctionalitiesRestoreModal = (
  props: FunctionalitiesRestoreModalProps,
) => {
  const {
    modalVisible,
    setModalVisible,
    onRestoreToPreviousState,
    onRestoreToDefault,
    currentSetting,
    currentFeatureId,
  } = props;
  const { t } = useContext(ContextApp);
  let currentFunctionality = currentSetting.find(
    (functionality) => functionality.feature_flag_id === currentFeatureId,
  )?.feature_flag;

  return (
    <Modal
      destroyOnClose
      title={
        <div className="modal-content__title">
          <span className="material-symbols-outlined receipt-exclamation-icon">
            error
          </span>
          {t('functionalities.restoreModal.title')}
        </div>
      }
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
      className="functionalities-restore-modal"
    >
      <div className="modal-content">
        <p className="modal-content__text">
          {t('functionalities.restoreModal.paragraph', {
            name: t(
              (currentFunctionality?.translation_key_name as never) || '',
            ) as string,
          })}
        </p>
        <div className="modal-content__actions">
          <ButtonTertiary onClick={() => setModalVisible(false)}>
            Cancelar
          </ButtonTertiary>
          <ButtonSecondary onClick={onRestoreToDefault}>
            {t('functionalities.restoreModal.restoreToDefault')}
          </ButtonSecondary>
          <ButtonAction onClick={onRestoreToPreviousState}>
            {t('functionalities.restoreModal.restoreToPreviousState')}
          </ButtonAction>
        </div>
      </div>
    </Modal>
  );
};

export default FunctionalitiesRestoreModal;
