import { Form } from 'antd';
import { useHistory, useParams } from 'react-router';
import { ITenant } from '../../interfaces';
import { useContext, useEffect, useState } from 'react';
import { notificationContext } from '../../contexts/NotificationContext';
import useTenantService from '../../hooks/useTenantService';
import ComponentWrapper from '../../components/common/ComponentWrapper/ComponentWrapper';
import CreditsSection from './CreditsSection/CreditsSection';
import { ReceiptsQuotaTenantPartial } from '../../interfaces/ReceiptQuotaTenant';
import moment from 'moment';
import { EnumsValues, TenantStatus } from '../../enums/EnumsValues';
import { CustomMessage } from '../../hooks';
import ButtonAction from '../../components/common/Buttons/ButtonAction';
import useReceiptQuotaService from '../../hooks/useReceiptQuotaService';
import './TenantEditAdmin.less';
import { ContextApp } from '../../contexts/ContextApp';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Functionalities } from '../FunctionalitiesPage/Functionalities';
import {
  IFeatureFlagConfigGeneralValue,
  IFeatureFlagConfiguration,
  IUpdateFeatureFlagConfigInuput,
} from '../../interfaces/FeatureFlag';
import { Query } from '../../services/graphql/query';
import { PageLoading } from '@ant-design/pro-layout';
import ButtonSecondary from '../../components/common/Buttons/ButtonSecondary';
import TenantInfo from './TenantInfo/TenantInfo';
import BillingInfo from './BillingInfo/BillingInfo';
import { RcFile } from 'antd/lib/upload';
import { IntegrationByTenant } from '../IntegrationByTenant/IntegrationByTenant';
import {
  IIntegration,
  IntegrationTenantUpdateInput,
} from '../../interfaces/Integration';

interface Params {
  tenant_id: string;
}
export interface UpdateTenantInput {
  name: string;
  tenant_picture_id?: number | null;
  targetName?: string;
  tenant_status_id?: number;
  billing_name?: string | null;
  address?: string | null;
  tax_type_id?: number | null;
  tax_number?: string | null;
  deleteCurrentImage?: boolean;
}

const REDIRECT_PATH = '/app/setting/tenants';
const DEFAULT_DATE = moment().add(1, 'month').toDate();

const TenantEditAdmin = () => {
  const history = useHistory();
  const { tenant_id } = useParams<Params>();
  const { t, getTenantsAssociatedWithUser } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const { customRequest, Mutation } = GraphqlService();
  const { setFeatureFlags } = useContext(ContextApp);
  const { updateReceiptsQuotaTenant } = useReceiptQuotaService();
  const { getTenant, updateTenant, createTenant } = useTenantService();
  const [form] = Form.useForm();
  const [tenant, setTenant] = useState<ITenant>();
  const [receiptQuotaTenant, setReceiptQuotaTenant] =
    useState<ReceiptsQuotaTenantPartial>();
  const [formLoading, setFormLoading] = useState(false);
  const [functionalities, setFunctionalities] = useState<
    IFeatureFlagConfigGeneralValue[]
  >([]);
  const [defaultFunctionalities, setDefaultFunctionalities] =
    useState<IFeatureFlagConfigGeneralValue[] | null>(null);
  const [initialTenantFunctionalities, setInitialTenantFunctionalities] =
    useState<IFeatureFlagConfigGeneralValue[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [fileToUpload, setFileToUpload] =
    useState<RcFile | undefined>(undefined);
  const [uploadImage, setUploadImage] = useState<boolean>(false);
  const [deleteCurrentImage, setDeleteCurrentImage] = useState<boolean>(false);
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);

  const getIntegration = async () => {
    try {
      const data: IIntegration[] = await customRequest({
        query: tenant_id ? Query.integrationsByTenant : Query.integrations,
        variables: { tenantId: Number(tenant_id), showInactive: true },
      });
      setIntegrations(data);
    } catch (error) {
      //intentional
    }
  };

  useEffect(() => {
    getIntegration();
  }, []);

  const getDefaultFeatureFags = async () => {
    try {
      const data: IFeatureFlagConfigGeneralValue[] = await customRequest({
        query: Query.getDefaultFeatureFlagsConfig,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getFeatureFlags = async (tenant_id: number) => {
    try {
      const data: IFeatureFlagConfiguration = await customRequest({
        query: Query.getAllFeatureFlagsConfigForTenantId,
        variables: { tenant_id },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateFunctionalities = async (tenantId: number) => {
    const updateFeaturesFlagsVariables: IUpdateFeatureFlagConfigInuput = {
      tenant_id: tenantId,
      value: functionalities.map((funcionality) => {
        return {
          feature_flag_id: funcionality.feature_flag_id,
          hidden: funcionality.hidden,
          available: funcionality.available,
        };
      }),
    };

    setFormLoading(true);
    try {
      await customRequest({
        mutation: Mutation.updateAllFeatureFlagConfigForTenantId,
        variables: { input: updateFeaturesFlagsVariables },
      });
    } catch (error: any) {
      setFormLoading(false);
    }
    setFormLoading(false);
  };

  const updateIntegrationStatus = async (tenantId: number) => {
    const updateIntegrationStatus: IntegrationTenantUpdateInput[] =
      integrations.map((integration) => {
        return {
          integration_id: integration.id,
          integration_status_id: integration.integration_status_id,
        };
      });

    setFormLoading(true);
    try {
      await customRequest({
        mutation: Mutation.updateIntegrationsByTenant,
        variables: {
          tenantId,
          integrationStatus: updateIntegrationStatus,
        },
      });
    } catch (error: any) {
      setFormLoading(false);
    }
    setFormLoading(false);
  };

  const restoreValues = (feature_flag_id: number, toState: string) => {
    if (!functionalities?.length) return;
    let previousFeatureValue = (
      toState === EnumsValues.FeatureFlagsRestoreValues.default
        ? defaultFunctionalities
        : initialTenantFunctionalities
    )?.find(
      (previousValue) => previousValue.feature_flag_id === feature_flag_id,
    );

    let restoredFeatureState = functionalities.map((feature) => {
      if (feature.feature_flag_id === feature_flag_id) {
        return previousFeatureValue
          ? {
              feature_flag_id: previousFeatureValue.feature_flag_id,
              feature_flag: previousFeatureValue.feature_flag,
              available: previousFeatureValue.available,
              hidden: previousFeatureValue.hidden,
            }
          : feature;
      }
      return feature;
    });
    setFunctionalities(restoredFeatureState);
  };

  const handleSwitchToggle = (
    checked: boolean,
    updatedFeature: IFeatureFlagConfigGeneralValue,
    element: string,
  ) => {
    let updatedFeatureState = functionalities?.map((feature) => {
      if (feature.feature_flag_id === updatedFeature.feature_flag_id) {
        return {
          ...feature,
          [element]:
            element === EnumsValues.FeatureFlagsSettings.hidden
              ? !checked
              : checked,
        };
      }
      return feature;
    });
    setFunctionalities(updatedFeatureState);
  };
  const changeSelect = (
    integrationStatusId: number,
    integration: IIntegration,
  ) => {
    setIntegrations((oldState: IIntegration[]) => {
      return oldState.map((state) => {
        return state.id === integration.id
          ? { ...state, integration_status_id: integrationStatusId }
          : state;
      });
    });
  };
  const handleSubmit = async (values: any) => {
    try {
      if (!tenant) {
        const createdTenant = await createTenant(
          {
            name: values.name,
            billing_name: values.billing_name,
            address: values.address,
            tax_type_id: values.tax_type_id,
            tax_number: values.tax_number,
            targetName: values.targetName,
            tenant_status_id: values.tenant_status_id,
          },
          fileToUpload && uploadImage ? fileToUpload : undefined,
        );

        await Promise.all([
          updateFunctionalities(createdTenant.id),
          updateIntegrationStatus(createdTenant.id),
          updateReceiptsQuotaTenant({
            tenant_id: createdTenant.id,
            receipts_quota: receiptQuotaTenant!.receipts_quota as number,
            quota_expiration_date: receiptQuotaTenant!
              .quota_expiration_date as Date,
          }),
        ]);

        setFeatureFlags((oldState: IFeatureFlagConfiguration) => {
          let newState = {
            ...oldState,
            featureFlagConfiguration: functionalities,
          };
          return newState;
        });

        openNotification({
          type: 'success',
          msj: t('message.tenantCreated'),
          context: 'TenantEditAdminPage.handleSubmit.1',
        });
      } else {
        await Promise.all([
          updateTenant(
            tenant.id,
            {
              name: values.name,
              billing_name: values.billing_name,
              address: values.address,
              tax_type_id: values.tax_type_id,
              tax_number: values.tax_number,
              targetName: values.targetName,
              tenant_status_id: values.tenant_status_id,
              deleteCurrentImage: deleteCurrentImage,
            },
            fileToUpload && uploadImage ? fileToUpload : undefined,
          ),
          updateFunctionalities(tenant.id),
          updateIntegrationStatus(tenant.id),
          updateReceiptsQuotaTenant({
            tenant_id: tenant.id,
            receipts_quota: receiptQuotaTenant!.receipts_quota as number,
            quota_expiration_date: receiptQuotaTenant!
              .quota_expiration_date as Date,
          }),
        ]);
        setFeatureFlags((oldState: IFeatureFlagConfiguration) => {
          let newState = {
            ...oldState,
            featureFlagConfiguration: functionalities,
          };
          return newState;
        });
        openNotification({
          type: 'success',
          msj: t('message.tenantUpdated'),
          context: 'TenantEditAdminPage.handleSubmit.2',
        });
      }
      getTenantsAssociatedWithUser();
      history.push({ pathname: '/app/setting/tenants' });
    } catch (error: any) {
      if (error.status_code && error.message) {
        return openNotification({
          type: 'error',
          msj: getErrorMessage(error),
          context: 'TenantEditAdminPage.handleSubmit.3',
        });
      }
      openNotification({
        type: 'error',
        msj: t('error.abm.update'),
        context: 'TenantEditAdminPage.handleSubmit.4',
      });
    }
  };

  const initializeData = async (tenantId: string) => {
    try {
      if (tenantId) {
        // Edicion de tenant

        const id = Number(tenantId);
        if (!isNaN(id)) {
          const tenantData = await getTenant(id);
          setTenant(tenantData);
          setReceiptQuotaTenant({
            receipts_quota: tenantData.receipts_quota_tenant?.receipts_quota,
            quota_expiration_date:
              tenantData.receipts_quota_tenant?.quota_expiration_date,
          });

          const defaultFeatureFlags = await getDefaultFeatureFags();
          setDefaultFunctionalities(defaultFeatureFlags);
          const customFeatureFlags = await getFeatureFlags(Number(tenant_id));

          if (customFeatureFlags.featureFlagConfiguration) {
            setFunctionalities(customFeatureFlags.featureFlagConfiguration);

            setFeatureFlags((oldState: IFeatureFlagConfiguration) => {
              let newState = {
                ...oldState,
                featureFlagConfiguration:
                  customFeatureFlags.featureFlagConfiguration,
              };
              return newState;
            });

            setInitialTenantFunctionalities(
              customFeatureFlags.featureFlagConfiguration,
            );
          } else {
            setFunctionalities(defaultFeatureFlags);
            setFeatureFlags((oldState: IFeatureFlagConfiguration) => {
              let newState = {
                ...oldState,
                featureFlagConfiguration: defaultFeatureFlags,
              };
              return newState;
            });
          }
        } else {
          openNotification({
            type: 'error',
            msj: 'El id del tenant no es válido',
            context: 'TenantEditAdmin.useEffect.1',
          });
          history.push(REDIRECT_PATH);
        }
      } else {
        // Creacion de tenant

        setReceiptQuotaTenant({
          receipts_quota: EnumsValues.ConstNumbers.ten,
          quota_expiration_date: DEFAULT_DATE,
        });

        const defaultFeatureFlags = await getDefaultFeatureFags();
        setDefaultFunctionalities(defaultFeatureFlags);
        setFunctionalities(defaultFeatureFlags);
        setFeatureFlags((oldState: IFeatureFlagConfiguration) => {
          let newState = {
            ...oldState,
            featureFlagConfiguration: defaultFeatureFlags,
          };
          return newState;
        });
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const handleCreditsChange = (key: string, value: any) => {
    setReceiptQuotaTenant((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    initializeData(tenant_id);
  }, [tenant_id]);

  useEffect(() => {
    form.setFieldsValue({
      name: tenant?.name,
      tenant_picture_ids: tenant?.tenant_picture_id,
      targetName: tenant?.target?.name || EnumsValues.TargetValues.General,
      tenant_status_id: tenant?.tenant_status_id || TenantStatus.Activa,
      billing_name: tenant?.billing_name,
      address: tenant?.address,
      tax_type_id: tenant?.tax_type_id,
      tax_number: tenant?.tax_number,
    });
  }, [tenant]);

  return loading && !functionalities?.length ? (
    <PageLoading />
  ) : (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => handleSubmit(values)}
      >
        <div className="tenant-edit-admin">
          <div className="tenant-edit-admin__actions">
            <ButtonSecondary
              className="tenant-edit-admin__actions--cancel"
              onClick={() => history.push({ pathname: '/app/setting/tenants' })}
            >
              {t('action.cancel')}
            </ButtonSecondary>
            <ButtonAction htmlType="submit" loading={formLoading}>
              {t('action.save')}
            </ButtonAction>
          </div>
          <div className="tenant-edit-admin__tenant-info">
            <ComponentWrapper
              title={t('tenantEditAdmin.basicInformation.title')}
            >
              <TenantInfo
                tenant={tenant}
                setFileToUpload={setFileToUpload}
                setUploadImage={setUploadImage}
                setDeleteCurrentImage={setDeleteCurrentImage}
              />
            </ComponentWrapper>
            <ComponentWrapper
              title={t('tenantEditAdmin.billingInformation.title')}
            >
              <BillingInfo tenant={tenant} form={form} />
            </ComponentWrapper>
            <ComponentWrapper title={t('tenantEditAdmin.creditsSection.title')}>
              <CreditsSection
                receiptQuotaTenant={receiptQuotaTenant}
                onCreditsChange={handleCreditsChange}
              />
            </ComponentWrapper>
          </div>
          <ComponentWrapper>
            <Functionalities
              handleSwitchToggle={handleSwitchToggle}
              functionalities={functionalities!}
              restoreValues={restoreValues}
              loading={loading}
              defaultFunctionalities={defaultFunctionalities}
              initialTenantFunctionalities={initialTenantFunctionalities}
            />
          </ComponentWrapper>
          <ComponentWrapper>
            <IntegrationByTenant
              integrations={integrations!}
              loading={loading}
              changeSelect={changeSelect}
            />
          </ComponentWrapper>
        </div>
      </Form>
    </>
  );
};

export default TenantEditAdmin;
