import { EnumsValues } from '../enums/EnumsValues';
import { extraDecimalWithDot } from '../pages/ReviewReceipt/regex/regexValues';
import { cleanSymbolsOnDecimal } from './cleanSymbolsOnDecimal';

const fillDecimals = (value: string, decimals: number): string => {
  const [integer, fraction = ''] = value.split('.');
  const truncatedFraction = fraction
    .slice(EnumsValues.ConstNumbers.zero, decimals)
    .padEnd(decimals, `${EnumsValues.ConstNumbers.zero}`);
  return `${integer}.${truncatedFraction}`;
};

export const formatValueOnLoad = (
  value: number | string,
  decimals: number = 2,
) => {
  let valueString = fillDecimals(value.toString(), decimals);

  if (extraDecimalWithDot.test(valueString)) {
    valueString = valueString.replace(/,/g, '');
    valueString = fillDecimals(valueString, decimals);
    valueString = valueString.replace('.', ',');
    valueString = valueString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } else {
    valueString = valueString.replace('.', ',');
    valueString = valueString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return cleanSymbolsOnDecimal(valueString);
};
