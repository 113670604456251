import React from 'react';
import './ComponentWrapper.less';

interface ComponentWrapperProps {
  borderRadius?: string;
  padding?: string;
  width?: string;
  height?: string;
  className?: string;
  children: React.ReactNode;
  title?: string;
}

const ComponentWrapper = (props: ComponentWrapperProps) => {
  const {
    borderRadius = '10px',
    padding = '20px',
    width,
    height,
    className = '',
    children,
    title,
  } = props;

  return (
    <div
      className={`component-wrapper ${className}`}
      style={{
        borderRadius,
        padding,
        width,
        height,
      }}
    >
      {title && <div className="component-wrapper__title">{title}</div>}
      {children}
    </div>
  );
};

export default ComponentWrapper;
