export const INTEGRATIONS = `
query integrations(
  $filter: FilterIntegrationInput
  $orderBy: IntegrationOrderInput
  $skip: Int
  $take: Int
  $tenantId: Int
  $showInactive : Boolean
) {
  integrations(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take, tenantId:$tenantId, showInactive: $showInactive) {
    __typename
    ... on Integration {
      id
      name
      description
      logo
      integration_status_id
      integration_status {
        name
      }
      order
      code
      allow_integration_finishes_in_finished
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const INTEGRATION_STATUS = `
query integrationStatus {
  integrationStatus {
    __typename
    ... on IntegrationStatus {
      id
      name
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const INTEGRATIONS_BY_TENANT = `
query integrationsByTenant($tenantId: Int!, $showInactive: Boolean
) {
  integrationsByTenant(tenantId:$tenantId, showInactive: $showInactive) {
    __typename
    ... on Integration {
      id
      name
      description
      logo
      integration_status_id
      integration_status {
        name
      }
      order
      code
      allow_integration_finishes_in_finished
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
