import { ITaxType } from '../interfaces/Tenant';
import GraphqlService from '../services/graphql/GraphqlService';

const useTaxTypeService = () => {
  const { Query, customRequest } = GraphqlService();

  const getTaxTypes = async (): Promise<ITaxType[]> => {
    return customRequest({
      query: Query.taxTypes,
    });
  };

  return {
    getTaxTypes,
  };
};

export default useTaxTypeService;
