import { ITarget } from '../interfaces/Target';
import GraphqlService from '../services/graphql/GraphqlService';

const useTargetService = () => {
  const { Query, customRequest } = GraphqlService();

  const getTargets = async (): Promise<ITarget[]> => {
    return customRequest({
      query: Query.targets,
    });
  };

  return {
    getTargets,
  };
};

export default useTargetService;
