export const UPDATE_FEATURE_FLAG_CONFIG_FOR_TENANT_ID = `
mutation updateFeatureFlagConfigForTenantId($input: CreateFeatureFlagConfigInput!)
 {
  updateFeatureFlagConfigForTenantId(input:$input){  
      __typename
      ... on FullFeatureFlagConfiguration {
        defaultAvailable
        defaultHidden
        featureFlagConfiguration {
          feature_flag{
            id
            name
            code
            description
            translation_key_name
            translation_key_description
            is_public
          }
          available
          hidden
        }
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }`;

export const UPDATE_ALL_FEATURE_FLAG_CONFIG_FOR_TENANT_ID = `
  mutation updateAllFeatureFlagConfigForTenantId($input: UpdateAllFeatureFlagConfigInput!)
   {
    updateAllFeatureFlagConfigForTenantId(input:$input){
        __typename
        ... on FeatureFlagConfigValue{
        tenant_id
        feature_flag_id
          feature_flag{
            id
            name
            code
            description
            translation_key_name
            translation_key_description
            is_public
          }
        hidden
        available
        }
        ... on ResultError {
          status_code
          message
          message_translation_key
        }
      }
    }`;
