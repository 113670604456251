export const UPDATE_RECEIPT_QUOTA_TENANT = `
mutation updateReceiptsQuotaTenant($input: UpdateReceiptsQuotaTenantInput!) {
  updateReceiptsQuotaTenant(input: $input) {
  
    __typename
    ... on ReceiptsQuotaTenant {
      receipts_quota
      quota_expiration_date
    }
    
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
