import { ITenantStatus } from '../interfaces/Tenant';
import GraphqlService from '../services/graphql/GraphqlService';

const useTenantStatusService = () => {
  const { Query, customRequest } = GraphqlService();

  const getTenantStatus = async (
    tenant_id: number,
  ): Promise<ITenantStatus[]> => {
    return customRequest({
      query: Query.tenantStates,
      variables: {
        filter: {
          tenant_id: tenant_id,
        },
      },
    });
  };

  return {
    getTenantStatus,
  };
};

export default useTenantStatusService;
