import { useContext, useLayoutEffect, useRef } from 'react';
import './SettingsHeader.less';
import { ContextApp } from '../../../contexts/ContextApp';
import { Button, Select } from 'antd';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { IReceiptType } from '../../../interfaces/Receipt';

interface SettingsHeaderProps {
  receiptTypes: IReceiptType[];
  onReceiptChange: (receiptType: IReceiptType) => void;
  onSave: () => void;
  setRestoreAllModalStatus: (status: boolean) => void;
  currentReceiptType: IReceiptType | null;
}

const SettingsHeader = (props: SettingsHeaderProps) => {
  const {
    receiptTypes,
    onReceiptChange,
    onSave,
    setRestoreAllModalStatus,
    currentReceiptType,
  } = props;
  const { t } = useContext(ContextApp);

  const toggleMenu = useRef<HTMLDivElement>(null);

  const [headerRef, isIntersecting] = useIntersectionObserver<HTMLDivElement>({
    threshold: 0,
  });

  useLayoutEffect(() => {
    const headerRefValue = headerRef.current;
    const toggleMenuValue = toggleMenu.current;
    if (headerRefValue && toggleMenuValue) {
      const width = headerRefValue.offsetWidth;
      toggleMenu.current?.style.setProperty('width', `${width}px`);
    }
  }, [headerRef.current, toggleMenu.current]);

  return (
    <>
      <div ref={headerRef} className={`settings-header`}>
        <div className={`settings-header__top`}>
          <div className={`settings-header__top__content`}>
            <h4 className="settings-header__top__content__title">
              {t('settingsPage.documentType.title')}
            </h4>
            <p className="settings-header__top__content__text">
              {t('settingsPage.documentType.paragraph')}
            </p>
          </div>
          <div className="settings-header__top__actions">
            <Button
              className="settings-header__top__actions__btn--restore"
              onClick={() => setRestoreAllModalStatus(true)}
            >
              {t('action.restoreAll')}
            </Button>
            <Button
              className="settings-header__top__actions__btn--save btn-save"
              onClick={onSave}
            >
              {t('action.save')}
            </Button>
          </div>
        </div>
        <div>
          <Select
            options={receiptTypes.map((item) => ({
              value: item.id,
              label:
                item.translation_key &&
                item.translation_key !== 'unavailable_key'
                  ? t(item.translation_key as never)
                  : item.name,
            }))}
            style={{ width: '216px ' }}
            defaultValue={currentReceiptType?.id}
            value={currentReceiptType?.id}
            onChange={(id) =>
              onReceiptChange(
                receiptTypes.find((item) => item.id === id) as IReceiptType,
              )
            }
          ></Select>
        </div>
      </div>

      <div
        ref={toggleMenu}
        className={`settings-header-toggle ${
          !isIntersecting ? 'settings-header-toggle--sticky' : ''
        }`}
      >
        <div className="settings-header-toggle__left">
          <p className="settings-header-toggle__left__description">
            {t('settingsPage.documentType.title')}:
          </p>
          <Select
            options={receiptTypes.map((item) => ({
              value: item.id,
              label:
                item.translation_key &&
                item.translation_key !== 'unavailable_key'
                  ? t(item.translation_key as never)
                  : item.name,
            }))}
            style={{ width: '216px ' }}
            defaultValue={currentReceiptType?.id}
            value={currentReceiptType?.id}
            onChange={(id) =>
              onReceiptChange(
                receiptTypes.find((item) => item.id === id) as IReceiptType,
              )
            }
          />
        </div>
        <div className="settings-header-toggle__right">
          <div className="settings-header__top__actions">
            <Button
              className="settings-header__top__actions__btn--restore"
              onClick={() => setRestoreAllModalStatus(true)}
            >
              {t('action.restoreAll')}
            </Button>
            <Button
              className="settings-header__top__actions__btn--save btn-save"
              onClick={onSave}
            >
              {t('action.save')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsHeader;
