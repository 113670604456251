import { useCallback, useContext, useRef, useState } from 'react';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { Switch } from 'antd';
import { ExportableColumn } from '../../shared/Exporter';
import { ContextApp } from '../../contexts/ContextApp';
import './Functionalities.less';
import { IFeatureFlagConfigGeneralValue } from '../../interfaces/FeatureFlag';
import { PageLoading } from '@ant-design/pro-layout';
import { ResetSettings } from '../../components/Icons/ResetSettings';
import FunctionalitiesRestoreModal from './FunctionalitiesRestoreModal';
import { EnumsValues } from '../../enums/EnumsValues';

export const Functionalities = (props: {
  functionalities: IFeatureFlagConfigGeneralValue[];
  defaultFunctionalities: IFeatureFlagConfigGeneralValue[] | null;
  initialTenantFunctionalities: IFeatureFlagConfigGeneralValue[] | null;
  restoreValues: (feature_flag_id: number, toState: string) => void;
  handleSwitchToggle: (
    checked: boolean,
    updatedFeature: IFeatureFlagConfigGeneralValue,
    element: string,
  ) => void;
  loading: boolean;
}) => {
  const { t } = useContext(ContextApp);
  const actionRef = useRef<ActionType>();
  const {
    functionalities,
    restoreValues,
    handleSwitchToggle,
    defaultFunctionalities,
    initialTenantFunctionalities,
    loading,
  } = props;
  const [restoreModalStatus, setRestoreModalStatus] = useState(false);
  const [currentFeatureId, setCurrentFeatureId] = useState<number | null>(null);
  const isDefaultValue = (record: IFeatureFlagConfigGeneralValue) => {
    let currentFeatureDefaultValue =
      defaultFunctionalities &&
      defaultFunctionalities
        .map((defaultFeatureValue) => {
          return {
            available: defaultFeatureValue.available,
            hidden: defaultFeatureValue.hidden,
            feature_flag_id: defaultFeatureValue.feature_flag_id,
          };
        })
        .find(
          (defaultFeatureValue) =>
            defaultFeatureValue.feature_flag_id === record.feature_flag_id,
        );
    let currentFeatureInitialValue =
      initialTenantFunctionalities &&
      initialTenantFunctionalities
        .map((initialFeatureValue) => {
          return {
            available: initialFeatureValue.available,
            hidden: initialFeatureValue.hidden,
            feature_flag_id: initialFeatureValue.feature_flag_id,
          };
        })
        .find(
          (initialFeatureValue) =>
            initialFeatureValue.feature_flag_id === record.feature_flag_id,
        );
    return (
      record.available === currentFeatureDefaultValue?.available &&
      record.hidden === currentFeatureDefaultValue?.hidden &&
      record.feature_flag_id === currentFeatureDefaultValue?.feature_flag_id &&
      record.available === currentFeatureInitialValue?.available &&
      record.hidden === currentFeatureInitialValue?.hidden &&
      record.feature_flag_id === currentFeatureInitialValue?.feature_flag_id
    );
  };

  const columns = useCallback(
    (): ExportableColumn<IFeatureFlagConfigGeneralValue>[] => [
      {
        title: t('functionalities.columns.name'),
        dataIndex: 'name',
        key: 'name',
        export: false,
        render: (_, record) => (
          <div>
            {record.feature_flag.translation_key_name
              ? t(record.feature_flag.translation_key_name as never)
              : record.feature_flag.name}
          </div>
        ),
      },
      {
        title: t('functionalities.columns.visible'),
        dataIndex: 'hidden',
        width: '15%',
        key: 'hidden',
        tooltip: {
          title: t('functionalities.tooltip.hidden'),
        },
        render: (_: any, record) => (
          <Switch
            className="protable-functionalities__switch"
            checked={!record.hidden}
            disabled={record.feature_flag.readonly}
            onChange={(checked: boolean) =>
              handleSwitchToggle(
                checked,
                record,
                EnumsValues.FeatureFlagsSettings.hidden,
              )
            }
          />
        ),
        hideInSearch: true,
        export: false,
      },
      {
        title: t('functionalities.columns.active'),
        dataIndex: 'available',
        width: '15%',
        key: 'available',
        tooltip: {
          title: t('functionalities.tooltip.available'),
        },

        render: (_: any, record) => (
          <Switch
            className="protable-functionalities__switch"
            checked={record.available}
            disabled={record.feature_flag.readonly}
            onChange={(checked: boolean) =>
              handleSwitchToggle(
                checked,
                record,
                EnumsValues.FeatureFlagsSettings.available,
              )
            }
          />
        ),
        hideInSearch: true,
        export: false,
      },
      {
        dataIndex: 'option',
        valueType: 'option',
        width: '10%',
        fixed: 'right',
        align: 'center',
        className: 'options-column',
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            <div className="functionalities-settings__icon-container">
              <ResetSettings
                onClick={() => {
                  setRestoreModalStatus(true);
                  setCurrentFeatureId(record.feature_flag_id);
                }}
                className={`functionalities-settings__icon ${
                  isDefaultValue(record) ? 'icon-disabled' : 'icon-default'
                }`}
              />
            </div>
          </>
        ),
      },
    ],
    [functionalities],
  );

  return loading && !functionalities ? (
    <PageLoading />
  ) : (
    <>
      <div className="data-validation__header">
        <h4 className="data-validation__header__title">
          {t('functionalities.title')}
        </h4>
      </div>
      <div className="protable-functionalities--wrapper">
        <ProTable
          actionRef={actionRef}
          className="protable-functionalities"
          columns={columns()}
          dataSource={functionalities}
          pagination={false}
          options={{ reload: false, setting: false, density: false }}
          search={false}
        />
        {functionalities && (
          <FunctionalitiesRestoreModal
            modalVisible={restoreModalStatus}
            setModalVisible={setRestoreModalStatus}
            currentSetting={functionalities}
            currentFeatureId={currentFeatureId}
            onRestoreToDefault={() => {
              setRestoreModalStatus(false);
              currentFeatureId &&
                restoreValues(
                  currentFeatureId,
                  EnumsValues.FeatureFlagsRestoreValues.default,
                );
            }}
            onRestoreToPreviousState={() => {
              setRestoreModalStatus(false);
              currentFeatureId &&
                restoreValues(
                  currentFeatureId,
                  EnumsValues.FeatureFlagsRestoreValues.initial,
                );
            }}
          />
        )}
      </div>
    </>
  );
};
