import { useContext, useEffect, useState } from 'react';
import type { TableColumnsType } from 'antd';
import { Table } from 'antd';

import { ContextApp } from '../../../../../contexts/ContextApp';
import { IReceiptDetail } from '../../../../../interfaces/Receipt';
import GraphqlService from '../../../../../services/graphql/GraphqlService';
import { IReceiptConceptExtracted } from '../../../../../interfaces/ReceiptConceptExtracted';
import { EnumsValues } from '../../../../../enums/EnumsValues';
import { formatValue } from '../../../../../shared/formatValue';

interface IUseGetDetailsProps {
  data: IReceiptDetail[] | undefined;
  headerId: number | undefined;
  tenantId: number | undefined;
}

const useGetDetails = (props: IUseGetDetailsProps) => {
  const [discountDetails, setDiscountDetails] =
    useState<IReceiptConceptExtracted[]>();
  const [taxDetails, setTaxDetails] = useState<IReceiptConceptExtracted[]>();
  const { data, headerId, tenantId } = props;

  const { t } = useContext(ContextApp);

  const { Query, customRequest } = GraphqlService();

  const getReceiptConceptExtracteds = async ({
    conceptMasterTypeId,
  }: {
    conceptMasterTypeId: number;
  }) => {
    try {
      const data: IReceiptConceptExtracted[] = await customRequest({
        query: Query.receiptConceptExtracteds,
        variables: {
          filter: {
            tenant_id: tenantId,
            receipt_header_id: headerId,
            concept_master_type_id: conceptMasterTypeId,
          },
        },
      });
      if (conceptMasterTypeId === EnumsValues.ConceptType.descuento) {
        setDiscountDetails(data);
      } else {
        setTaxDetails(data);
      }
    } catch (error) {
      //Intentionally left blank
    }
  };

  useEffect(() => {
    getReceiptConceptExtracteds({
      conceptMasterTypeId: EnumsValues.ConceptType.impuesto,
    });
    getReceiptConceptExtracteds({
      conceptMasterTypeId: EnumsValues.ConceptType.descuento,
    });
  }, []);

  const expandedRowRender = (props: any) => {
    const columns: TableColumnsType<any> = [
      {
        title: t('ReceiptTray.modalViewReceipt.detailsTab.code'),
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: t('ReceiptTray.modalViewReceipt.detailsTab.costCenter'),
        dataIndex: 'costCenter',
        key: 'costCenter',
      },
      {
        title: t('ReceiptTray.modalViewReceipt.detailsTab.product'),
        dataIndex: 'product',
        key: 'product',
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={[
          {
            key: props?.id,
            code: props.product?.code || '-',
            costCenter: props?.cost_center_id || '-',
            product: props.product?.description || '-',
          },
        ]}
        pagination={false}
        tableLayout="auto"
      />
    );
  };

  const detailItemsColumn: TableColumnsType<any> = [
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t(
        'ReceiptTray.modalViewReceipt.detailsTab.receiptDetailItemTypeInTable',
      ),
      dataIndex: 'receiptDetailItemType',
      render: (_text, record) => {
        return `${record.receiptDetailItemType}`;
      },
      key: 'receiptDetailItemType',
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.quantityInTable'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.unitPriceInTable'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      align: 'right',
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.discount'),
      dataIndex: 'discount',
      key: 'discount',
      align: 'right',
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.discountPercentage'),
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      align: 'right',
      width: 80,
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.iva'),
      dataIndex: 'iva',
      key: 'iva',
      align: 'right',
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.percentageIva'),
      dataIndex: 'ivaPercentage',
      key: 'ivaPercentage',
      align: 'right',
      width: 80,
    },
    {
      title: t(
        'ReceiptTray.modalViewReceipt.detailsTab.subtotalWithoutTaxInTable',
      ),
      dataIndex: 'subtotalWithoutTax',
      key: 'subtotalWithoutTax',
      align: 'right',
    },
    {
      title: t(
        'ReceiptTray.modalViewReceipt.detailsTab.subtotalWithIvaInTable',
      ),
      dataIndex: 'subtotal',
      key: 'subtotal',
      align: 'right',
    },
  ];

  const discountDetailsColumn: TableColumnsType<any> = [
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.description'),
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.concept'),
      dataIndex: 'concept_master.name',
      key: 'concept_master.name',
      render: (_text, record) => {
        return `${record?.concept_master?.name ?? ''}`;
      },
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.amount'),
      dataIndex: 'amount',
      render: (_text, record) => {
        return `${formatValue(record.amount)}`;
      },
      key: 'amount',
      align: 'right',
    },
  ];

  const taxDetailsColumn: TableColumnsType<any> = [
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.description'),
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.concept'),
      dataIndex: 'concept_master.name',
      key: 'concept_master.name',
      render: (_text, record) => {
        return `${record?.concept_master?.name ?? ''}`;
      },
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.percentageIva'),
      dataIndex: 'receipt_tax_type.tax_rate',
      render: (_text, record) => {
        return `${formatValue(record.receipt_tax_type?.tax_rate)} %`;
      },
      key: 'receipt_tax_type.tax_rate',
      align: 'right',
    },
    {
      title: t('ReceiptTray.modalViewReceipt.detailsTab.amount'),
      dataIndex: 'amount',
      render: (_text, record) => {
        return `${formatValue(record.amount)}`;
      },
      key: 'amount',
      align: 'right',
    },
  ];

  const detailItemsData = data?.map((item) => {
    return {
      key: item.id,
      description: item.description,
      quantity: formatValue(item.quantity, EnumsValues.ConstNumbers.four),
      unitPrice: formatValue(item.unit_price, EnumsValues.ConstNumbers.four),
      discount: formatValue(item.receipt_line_discount_total),
      discountPercentage: formatValue(item.discount_percentage),
      iva: formatValue(item.receipt_line_tax_total),
      ivaPercentage: formatValue(item.tax_rate),
      subtotalWithoutTax: formatValue(item.subtotal_without_tax),
      subtotal: formatValue(item.subtotal),
      receiptDetailItemType: item.receipt_detail_item_type?.name,
      code: item.code,
      cost_center_id: item.cost_center_id,
      product: item.product,
    };
  });

  const subTotalDetailItemsWithoutFormat =
    data?.reduce((acc, item) => {
      return acc + (item.subtotal_without_tax || 0);
    }, 0) ?? 0;

  const subTotalDetailItems = formatValue(subTotalDetailItemsWithoutFormat);
  // TODO:Revisar porque el valor varia en 1 centavo a veces con el receipt_total

  const subTotalDiscountDetailsWithoutFormat =
    discountDetails?.reduce((acc, item) => {
      return acc + (item.amount || 0);
    }, 0) ?? 0;

  const subTotalDiscountDetails = formatValue(
    subTotalDiscountDetailsWithoutFormat,
  );

  const subTotalTaxDetailsWithoutFormat =
    taxDetails?.reduce((acc, item) => {
      return acc + (item.amount || 0);
    }, 0) ?? 0;

  const subTotalTaxDetails = formatValue(subTotalTaxDetailsWithoutFormat);

  return {
    detailItemsColumn,
    discountDetailsColumn,
    taxDetailsColumn,
    expandedRowRender,
    detailItemsData,
    subTotalDetailItems,
    discountDetails,
    taxDetails,
    subTotalDiscountDetails,
    subTotalTaxDetails,
  };
};
export default useGetDetails;
