import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { Alert, Button, Form, Select, Upload } from 'antd';
import { ITenant } from '../../../interfaces';
import Input from 'antd/lib/input/Input';
import './TenantInfo.less';
import { CustomMessage } from '../../../hooks';
import { notificationContext } from '../../../contexts/NotificationContext';
import { ITarget } from '../../../interfaces/Target';
import useTargetService from '../../../hooks/useTargetService';
import { ITenantStatus } from '../../../interfaces/Tenant';
import useTenantStatusService from '../../../hooks/useTenantStatusService';
import { EnumsValues, TenantStatus } from '../../../enums/EnumsValues';
import { RcFile } from 'antd/lib/upload';
import useAppSettingService from '../../../hooks/useAppSettingService';
import { Tools } from '../../../shared';
import useTenantService from '../../../hooks/useTenantService';

interface Props {
  tenant: ITenant | undefined;
  setFileToUpload: (file: (RcFile & { filename?: string }) | undefined) => void;
  setUploadImage: (boolean: boolean) => void;
  setDeleteCurrentImage: (boolean: boolean) => void;
}

const TenantInfo = (props: Props) => {
  const { tenant, setFileToUpload, setUploadImage, setDeleteCurrentImage } =
    props;

  const { t } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const { getTargets } = useTargetService();
  const { getTenantStatus } = useTenantStatusService();
  const { getAppSetting } = useAppSettingService();
  const { getTenantPicture: getPicture, getTenantByName } = useTenantService();

  const [targets, setTargets] = useState<ITarget[]>([]);
  const [tenantStatesCombo, setTenantStatesCombo] = useState<ITenantStatus[]>(
    [],
  );
  const [errorFile, setErrorFile] = useState<string>();
  const [fileMetaData, setFileMetaData] =
    useState<RcFile & { filename?: string }>();
  const [loadingTenantPicture, setLoadingTenantPicture] =
    useState<boolean>(false);
  const [urlFile, setUrlFile] = useState<string>();
  const [maxSizeFile, setMaxSizeFile] = useState<number>(
    EnumsValues.SystemLimits.MaxSizeOfFiles,
  );

  const initializeData = async () => {
    try {
      const targetsResponse = await getTargets();
      setTargets(targetsResponse);

      const tenantStatusResponse = await getTenantStatus(tenant?.id || 0);
      setTenantStatesCombo(tenantStatusResponse);

      const limitMaxSizeFile = await getAppSetting(
        EnumsValues.SettingNames.LimitMaxSizeFile,
      );
      setMaxSizeFile(Number(limitMaxSizeFile.setting_value));
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'tenantPage.targets.1',
        msj: getErrorMessage(error),
      });
    }
  };

  const uploadRequest = (uploadRequestOptions: any) => {
    setLoadingTenantPicture(() => true);
    const { onSuccess, file } = uploadRequestOptions;
    const fileRc = file as RcFile;
    if (fileRc.size > maxSizeFile) {
      setLoadingTenantPicture(() => false);
      setErrorFile(t('error.abm.imageMaxSize'));
    } else {
      setErrorFile(undefined);
      Tools.getBase64WithCallback(file, (fileUrl: string) => {
        setUrlFile(fileUrl);
      });
      setFileMetaData(fileRc);
      if (onSuccess) {
        setLoadingTenantPicture(false);
        setUploadImage(true);
        setDeleteCurrentImage(false);

        // TODO: Se realiza de esta manera por un bug de antd
        setTimeout(onSuccess, 0);
      }
    }
  };

  const uploadButton = (
    <Button className="tenant-upload-btn">
      <span
        className="material-symbols-outlined tenant-upload-icon"
        translate="no"
      >
        upload
      </span>
      {t('action.upload')}
    </Button>
  );

  const getTenantPicture = async (tenant_picture_id?: number) => {
    if (!tenant_picture_id) return;
    setLoadingTenantPicture(true);
    try {
      const data = await getPicture(tenant_picture_id);
      const url: string = Tools.getUrlOfBase64File({
        mimetype: data.mimetype,
        fileBase64: data.file,
      });

      if (true) {
        setUrlFile(url);
        setFileMetaData(data);
      }
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'CompleteTenant.getTenantPicture.1',
        msj: getErrorMessage(error),
      });
    } finally {
      setLoadingTenantPicture(false);
    }
  };

  const validateName = async (
    _rule: any,
    value: any,
    _validatorCallback: any,
  ): Promise<string> => {
    if (!Tools.validateTenantName(value))
      return Promise.reject(t('error.backend.tenantNameFormat'));
    return new Promise(async (resolve, reject) => {
      if (value) {
        try {
          const tenantResult = await getTenantByName(value);
          if (tenantResult && (!tenant || tenant.name !== tenantResult.name)) {
            reject(t('error.backend.nameNotAvailable'));
          } else {
            resolve('');
          }
        } catch (err) {
          resolve('');
        }
      } else {
        resolve('');
      }
    });
  };

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if (tenant) {
      getTenantPicture(tenant.tenant_picture_id);
    }
  }, [tenant]);

  useEffect(() => {
    setFileToUpload(fileMetaData);
  }, [fileMetaData]);

  return (
    <div className="tenant-info">
      <Form.Item
        className="tenant-info__form-item"
        name="name"
        label={`${t('entity.name')}`}
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: t('error.abm.nameRequired'),
          },
          {
            validator: validateName,
          },
        ]}
      >
        <Input
          type="text"
          id="name"
          aria-label="name"
          value={tenant?.name}
          placeholder={`${t('action.input.enter')} ${t(
            'entity.name',
          ).toLocaleLowerCase()}`}
          minLength={4}
        />
      </Form.Item>

      {errorFile ? (
        <Alert
          style={{ marginTop: '14px' }}
          message={errorFile}
          type="error"
          showIcon
        />
      ) : null}
      <Form.Item
        name="tenant_picture_ids"
        label={`${t('entity.image')} ${t('action.optional')}`}
        className="tenant-info__form-item"
      >
        <Upload
          fileList={
            loadingTenantPicture || fileMetaData
              ? [
                  {
                    status: loadingTenantPicture ? 'uploading' : undefined,
                    name: fileMetaData?.filename || fileMetaData?.name || '-',
                    uid: '1',
                    url: urlFile,
                  },
                ]
              : undefined
          }
          accept="image/jpeg, image/png, image/jpg"
          listType="picture"
          className="avatar-uploader"
          showUploadList={true}
          onRemove={() => {
            setErrorFile(undefined);
            setFileMetaData(undefined);
            setLoadingTenantPicture(false);
            setUploadImage(false);
            setUrlFile(undefined);
            setDeleteCurrentImage(true);
          }}
          multiple={false}
          maxCount={1}
          customRequest={(uploadRequestOptions) => {
            uploadRequest(uploadRequestOptions);
          }}
        >
          {uploadButton}
        </Upload>
      </Form.Item>

      <Form.Item
        name="targetName"
        label={`${t('entity.segment')} ${t('action.optional')}`}
        className="tenant-info__form-item"
      >
        <Select
          options={targets.map((target) => ({
            label: target.description,
            value: target.name,
          }))}
          getPopupContainer={(node) => node.parentNode}
          placeholder={`${t('action.input.select')} ${t(
            'entity.segment',
          ).toLocaleLowerCase()}`}
          showSearch
          filterOption={(inputValue: string, option: any) =>
            option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
          allowClear
        />
      </Form.Item>

      <Form.Item
        name="tenant_status_id"
        label={`${t('entity.status')} ${t('action.optional')}`}
        className="tenant-info__form-item"
      >
        <Select
          id="tenant_status_id"
          options={tenantStatesCombo
            .map((state) => ({
              label: t(state.translation_key as never, {
                defaultValue: state.name,
              }),
              value: state.id,
            }))
            .filter((state) =>
              !tenant
                ? state.value.toString() !==
                  TenantStatus.BajaSolicitada.toString()
                : state,
            )}
          placeholder={`${t('action.input.select')} ${t(
            'entity.status',
          ).toLocaleLowerCase()}`}
          getPopupContainer={(node) => node.parentNode}
          showSearch
          filterOption={(inputValue: string, option: any) =>
            option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
        />
      </Form.Item>
    </div>
  );
};

export default TenantInfo;
