import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ExportableColumn } from '../../shared/Exporter';
import GraphqlService, {
  IFileData,
} from '../../services/graphql/GraphqlService';
import { Tools, ABM } from '../../shared';
import { ContextApp } from '../../contexts/ContextApp';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import './ReceiptLogs.less';
import { ILog } from '../../interfaces/Log';
import moment from 'moment';
import {
  Badge,
  Button,
  Card,
  Collapse,
  DatePicker,
  Empty,
  Timeline,
  Tooltip,
} from 'antd';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import { CustomMessage } from '../../hooks';
import defaultTranslation from '../../locales/es/translations.json';
import { useParams } from 'react-router';
import { IReceiptHeader } from '../../interfaces/Receipt';
import { useHistory } from 'react-router-dom';
import { NavContext } from '@ionic/react';
import { EnumsValues } from '../../enums/EnumsValues';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import breaks from 'remark-breaks';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { notificationContext } from '../../contexts/NotificationContext';
import ToolbarFilterOrder from '../../components/common/ToolbarFilterOrder/ToolbarFilterOrder';
import useReceiptService from '../../hooks/useReceiptService';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_SORTER: string[] = [];

interface Params {
  receipt_id: string;
}

export default function ReceiptLogs() {
  const history = useHistory();
  const { receipt_id } = useParams<Params>();

  // states
  const { t } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const { getDateWithTime, getDate, getUserDateFormat } =
    MomentTimezoneService();
  const [dataTable, setDataTable] = useState<ILog[]>([]);
  const [disableReview, setDisableReview] = useState<boolean>(false);
  const [receiptHeader, setReceiptHeader] = useState<IReceiptHeader>();
  // services and hooks
  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });
  const { Query, customRequest } = GraphqlService();
  const { getErrorMessage } = CustomMessage();
  const { getReceiptHeader } = useReceiptService();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const { navigate } = useContext(NavContext);
  const customParamsRef = useRef<any>({ filter: {} });

  // contexts

  const getReceiptUpdated = async (receipt_id: number) => {
    try {
      const data: IReceiptHeader = await getReceiptHeader(receipt_id);

      if (data.status_id !== EnumsValues.ReceiptStatus.ToReview) {
        setDisableReview(true);
      }
      setReceiptHeader(data);
      return data;
    } catch (error: any) {
      if (error.message === 'Registro no encontrado') {
        openNotification({
          msj: t('error.backend.recordNotFoundMessage'),
          type: 'error',
        });
      } else {
        openNotification({
          msj: t('error.backend.generalErrorMessage'),
          type: 'error',
        });
      }
      history.push({
        pathname: '/app/receipt',
      });
    }
  };
  const reviewValidation = async (receipt_id: number) => {
    const data = await getReceiptUpdated(receipt_id);

    if (data && data.status_id !== EnumsValues.ReceiptStatus.ToReview) {
      openNotification({
        msj: t('ReviewReceipt.messageError.receiptReviewError'),
        type: 'warning',
      });
    } else {
      navigate(`reviewReceipt/${receipt_id}`);
    }
  };

  // methods
  const exportLogs = async () => {
    try {
      const data: IFileData = await customRequest({
        query: Query.exportLogs,
        variables: {
          format: 'xlsx',
          filter: variables.current.input,
          dataIndexes: {
            tenant: t('entity.tenant'),
            log_action: t('log_action.header'),
            user: t('entity.user'),
            receipt_header_id: t('entity.receipt_id'),
            date: t('entity.date'),
            description: t('entity.description'),
          },
          translationData: JSON.stringify(defaultTranslation.translation),
        },
      });
      if (data) {
        Tools.downloadFile(data);
      }
    } catch (error) {
      openNotification({
        type: 'error',
        context: 'Receipt.exportReceiptsLogs.1',
        msj: getErrorMessage(error),
      });
    }
  };

  useEffect(() => {
    const el = document.getElementById('registered-header');
    if (el !== null) {
      el.scrollIntoView();
    }

    const receiptId = Number(receipt_id);
    if (!isNaN(receiptId)) {
      getReceiptUpdated(receiptId);
    } else {
      openNotification({
        msj: t('error.abm.invalidId'),
        type: 'error',
      });
      history.push({
        pathname: '/app/receipt',
      });
    }
  }, [receipt_id]);

  /**
   * Se configura por cada ABM diferente
   */

  const request = async (params: any) => {
    const DATE_RANGE = 'date_range';
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    variables.current.input = {};

    const search: any = ABM.valuesResult(params);

    if (customParamsRef.current.filter[DATE_RANGE]) {
      customParamsRef.current.filter[DATE_RANGE] =
        customParamsRef.current.filter[DATE_RANGE].map((date: Date) =>
          moment(date),
        );
      [variables.current.input.from_date, variables.current.input.to_date] =
        customParamsRef.current.filter[DATE_RANGE];
    }

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    if (receiptHeader)
      variables.current.input.receipt_header_id = receiptHeader.id;
    try {
      const data: ILog[] = await customRequest({
        query: Query.getLogs,
        variables: variables.current,
      });
      setDataTable(() => data);
      return {
        current: 1,
        data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      openNotification({
        type: 'error',
        context: 'ReceiptLogs.request.1',
        msj: getErrorMessage(error),
      });
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const columns = useCallback(
    (): ExportableColumn<ILog>[] => [
      {
        export: true,
        dataIndex: 'date_range',
        title: t('entity.date'),
        renderFormItem: () => (
          <DatePicker.RangePicker
            allowEmpty={[true, true]}
            placeholder={[t('entity.dateFrom'), t('entity.dateTo')]}
            format={getUserDateFormat()}
            className="receipt-log-date-range-input"
          ></DatePicker.RangePicker>
        ),
        align: 'left',
        hideInTable: true,
        hideInForm: true,
      },
    ],
    [],
  );

  const LogTimeline = useMemo(
    () => (
      <Timeline mode="left" className="timeline-receipt-log">
        {dataTable.map((data, index) => (
          <Timeline.Item
            key={data.id}
            label={getDateWithTime({ element: data.its })}
            color={data.log_action?.color || 'gray'}
            dot={<Badge color={data.log_action?.color || 'gray'} />}
          >
            <div>
              <Collapse
                className="collapse-logs"
                bordered={false}
                defaultActiveKey={'0'}
              >
                <CollapsePanel
                  header={
                    <b>
                      {t(data.log_action?.translation_key as never, {
                        defaultValue: data.log_action?.name,
                      })}
                    </b>
                  }
                  key={index}
                >
                  <div>
                    <ReactMarkdown
                      className="user-logs"
                      children={`_${t(
                        'entity.user',
                      )}: ${data.createdBy?.firstname.trim()} ${data.createdBy?.lastname.trim()}_`}
                      remarkPlugins={[breaks]}
                    />
                  </div>

                  {data.description && (
                    <div>
                      <ReactMarkdown
                        children={` ${data.description.replaceAll(
                          /{{(.+?)}}/g,
                          (_, key) => t(key as never),
                        )}`}
                        remarkPlugins={[breaks]}
                      />
                    </div>
                  )}
                </CollapsePanel>
              </Collapse>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    ),
    [dataTable],
  );

  return (
    <>
      {receiptHeader && (
        <ProTable<ILog>
          onSizeChange={mobileOnSizeChangeProTable}
          components={{
            table: showComponent(),
          }}
          actionRef={actionRef}
          rowKey="id"
          tableRender={() => (
            <div className="ant-pro-card-logs">
              <div className="ant-pro-card-body-logs">
                <div className="ant-pro-card-body-logs__filters">
                  <ToolbarFilterOrder
                    showButtonNew={false}
                    hideSearch
                    columns={columns()}
                    onFinishFilter={(values) => {
                      customParamsRef.current.filter = values;
                      if (actionRef.current?.reload) {
                        actionRef.current.reload(true);
                      }
                    }}
                  />
                </div>
                <div className="ant-pro-table-list-toolbar-container-logs">
                  <Button
                    className="receipt-back-link receipt-back-link-logs"
                    icon={
                      <span
                        className="material-symbols-outlined receipt-back-icon"
                        translate="no"
                      >
                        navigate_before
                      </span>
                    }
                    onClick={() => {
                      history.push({
                        pathname: '/app/receipt',
                        state: {
                          keepFilter: true,
                        },
                      });
                    }}
                  >
                    {t('action.return')}
                  </Button>
                  <div>
                    <Button onClick={exportLogs}>{t('action.download')}</Button>
                    <Button
                      className={`receipt-review-btn ${
                        disableReview === true ? `disabled-color-logReview` : ``
                      }`}
                      onClick={() => {
                        reviewValidation(receiptHeader.id);
                      }}
                      disabled={disableReview}
                    >
                      {t('ReviewReceipt.reviewReceipt')}
                    </Button>
                  </div>
                </div>
                {dataTable.length ? (
                  <div className="timeline-container">
                    <Card className="timeline-receipt-card">
                      <span
                        className="material-symbols-outlined timeline-receipt-card-icon"
                        translate="no"
                      >
                        description
                      </span>
                      <div className="receipt-general-info-log">
                        <p>{t('entity.sender')}</p>
                        <b>{receiptHeader.sender?.name}</b>
                      </div>
                      <div className="receipt-general-info-log">
                        <p>{t('entity.type')}</p>{' '}
                        <b>{receiptHeader.receipt_type?.name}</b>
                      </div>
                      <div className="receipt-general-info-log">
                        <p>{t('entity.receipt_number')}</p>{' '}
                        <b>{receiptHeader.receipt_number}</b>
                      </div>
                      <div className="receipt-general-info-log">
                        <p>{t('entity.receiptDate')}</p>{' '}
                        <b>
                          {getDate({ element: receiptHeader.receipt_date })}
                        </b>
                      </div>
                      <div className="receipt-general-info-log">
                        <p>{t('action.loaded')}</p>{' '}
                        {receiptHeader?.from_email ? (
                          receiptHeader.from_email.length > 20 ? (
                            <Tooltip
                              title={receiptHeader.from_email}
                              placement="bottom"
                            >
                              <b>
                                {`${receiptHeader.from_email.substring(
                                  0,
                                  20,
                                )}...`}
                              </b>
                            </Tooltip>
                          ) : (
                            <b>{receiptHeader.from_email}</b>
                          )
                        ) : (
                          <b>{receiptHeader.receipt_source.name}</b>
                        )}
                      </div>
                    </Card>
                    {LogTimeline}
                  </div>
                ) : (
                  <Empty
                    className="pro-table-for-mobile__empty"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>{t('message.noData')}</span>}
                  />
                )}
              </div>
            </div>
          )}
          search={false}
          /**
           * @description este metodo debe poder ejecutar siempre la consulta al backend
           */
          request={async (params, sorter, filter) => {
            return request({ ...params, sorter, filter });
          }}
          columns={columns()}
        />
      )}
    </>
  );
}
