import { RcFile } from 'antd/lib/upload';
import { ITenant } from '../interfaces';
import { IUpdateTenantInput } from '../interfaces/Tenant';
import GraphqlService from '../services/graphql/GraphqlService';

const useTenantService = () => {
  const { Query, customRequest, Mutation, customFileRequest } =
    GraphqlService();

  const getTenant = async (tenant_id: number): Promise<ITenant> => {
    return customRequest({
      query: Query.tenant,
      variables: {
        input: {
          id: tenant_id,
        },
      },
    });
  };

  const getTenantPicture = async (tenant_picture_id: number) => {
    return customRequest({
      query: Query.getTenantPicture,
      variables: {
        id: tenant_picture_id,
      },
    });
  };

  const getTenantByName = async (name: string) => {
    return customRequest({
      query: Query.tenant,
      variables: {
        input: {
          name: name,
        },
      },
    });
  };

  const updateTenant = async (
    tenant_id: number,
    input: IUpdateTenantInput,
    file: (RcFile & { filename?: string }) | undefined = undefined,
  ) => {
    return customFileRequest(
      {
        mutation: Mutation.updateTenant,
        variables: {
          id: tenant_id,
          input: input,
          file: file
            ? {
                filename: file?.name,
                mimetype: file?.type,
                encoding: 'base64',
              }
            : file,
        },
      },
      file
        ? [
            {
              file,
              path: 'variables.file',
            },
          ]
        : [],
    );
  };

  const createTenant = async (
    input: IUpdateTenantInput,
    file: (RcFile & { filename?: string }) | undefined = undefined,
  ) => {
    return customFileRequest(
      {
        mutation: Mutation.createTenant,
        variables: {
          input: input,
          file: file
            ? {
                filename: file?.name,
                mimetype: file?.type,
                encoding: 'base64',
              }
            : file,
        },
      },
      file
        ? [
            {
              file,
              path: 'variables.file',
            },
          ]
        : [],
    );
  };

  return {
    getTenant,
    getTenantPicture,
    getTenantByName,
    updateTenant,
    createTenant,
  };
};

export default useTenantService;
