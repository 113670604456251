import { EnumsValues } from '../../../../enums/EnumsValues';
import { cleanSymbolsOnDecimal } from '../../../../shared/cleanSymbolsOnDecimal';
import { truncateDecimals } from '../../../../shared/truncateDecimals';
import {
  commaDecimalFormat,
  decimalWithoutInteger,
  dotDecimalFormat,
  extraDecimalWithComma,
  extraDecimalWithDot,
  extraDigitsWithDotOrCommaFormat,
  optionalCommaDecimalFormat,
  optionalDotDecimalFormat,
  contentWithDecimalOrDotOrComa,
  decimalWithOnlyDotsNotComma,
} from '../../regex/regexValues';

const number = (content: string): string => {
  if (!optionalCommaDecimalFormat.test(content)) {
    if (
      (content.indexOf(EnumsValues.Symbols.comma) >
        EnumsValues.ConstNumbers.negativeOne ||
        content.indexOf(EnumsValues.Symbols.point) >
          EnumsValues.ConstNumbers.negativeOne) &&
      contentWithDecimalOrDotOrComa.test(content)
    ) {
      if (extraDecimalWithComma.test(content)) {
        content = content.replace(/\./g, EnumsValues.Symbols.emptyString);
        content = content.replace(
          EnumsValues.Symbols.comma,
          EnumsValues.Symbols.point,
        );
        content = truncateDecimals(
          Number(content),
          EnumsValues.ConstNumbers.four,
        ).toString();
        content = content.replace(
          EnumsValues.Symbols.point,
          EnumsValues.Symbols.comma,
        );
        content = content.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          EnumsValues.Symbols.point,
        );
      } else if (extraDecimalWithDot.test(content)) {
        content = content.replace(/,/g, EnumsValues.Symbols.emptyString);
        content = truncateDecimals(
          Number(content),
          EnumsValues.ConstNumbers.four,
        ).toString();
        content = content.replace(
          EnumsValues.Symbols.point,
          EnumsValues.Symbols.comma,
        );
        content = content.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          EnumsValues.Symbols.point,
        );
      } else if (decimalWithOnlyDotsNotComma.test(content)) {
        content = content.replace(/\.(?=\d{2}$)/, EnumsValues.Symbols.comma);
      } else {
        content = content.replace(/,/g, '+');
        content = content.replace(/\./g, EnumsValues.Symbols.comma);
        content = content.replace(/\+/g, EnumsValues.Symbols.point);
      }
    } else if (
      content.split(EnumsValues.Symbols.comma).length -
        EnumsValues.ConstNumbers.one >
        EnumsValues.ConstNumbers.one &&
      contentWithDecimalOrDotOrComa.test(content)
    ) {
      content = content.replace(
        EnumsValues.Symbols.comma,
        EnumsValues.Symbols.point,
      );
    } else if (commaDecimalFormat.test(content)) {
      content = content.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        EnumsValues.Symbols.point,
      );
    } else if (dotDecimalFormat.test(content)) {
      content = content.replace(
        EnumsValues.Symbols.point,
        EnumsValues.Symbols.comma,
      );
    } else if (optionalDotDecimalFormat.test(content)) {
      content = content.replace(
        EnumsValues.Symbols.point,
        EnumsValues.Symbols.comma,
      );
      content = content.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        EnumsValues.Symbols.point,
      );
    } else if (extraDigitsWithDotOrCommaFormat.test(content)) {
      if (
        content.indexOf(EnumsValues.Symbols.comma) >
        EnumsValues.ConstNumbers.negativeOne
      ) {
        content = content.replace(
          EnumsValues.Symbols.comma,
          EnumsValues.Symbols.point,
        );
      }
      content = truncateDecimals(
        Number(content),
        EnumsValues.ConstNumbers.four,
      ).toString();
      content = content.replace(
        EnumsValues.Symbols.point,
        EnumsValues.Symbols.comma,
      );
      content = content.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        EnumsValues.Symbols.point,
      );
    } else if (decimalWithoutInteger.test(content)) {
      content = content.replace(
        decimalWithoutInteger,
        (match: string) => `,${match.substring(EnumsValues.ConstNumbers.one)},`,
      );
    }
  }
  return cleanSymbolsOnDecimal(content);
};

export default number;
