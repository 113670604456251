export const UPDATE_INTEGRATIONS_BY_TENANT = `
mutation updateIntegrationsByTenant($tenantId: Int!, $integrationStatus:[IntegrationTenantUpdateInput!]!
) {
  updateIntegrationsByTenant(tenantId:$tenantId, integrationStatus:$integrationStatus) {
    __typename
    ... on Integration {
      id
      name
      description
      logo
      integration_status_id
      integration_status {
        name
      }
      order
      code
      allow_integration_finishes_in_finished
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
