export const optionalCommaDecimalFormat = /^-?\d{1,3}(\.\d{3})*(,\d{1,4})?$/;
export const commaDecimalFormat = /^-?\d+,\d{1,3}$/;
export const dotDecimalFormat = /^-?\d{1,3}\.\d{1,2}$/;
export const optionalDotDecimalFormat = /^-?\d+\.\d{1,2}$/;
export const extraDigitsWithDotOrCommaFormat = /^-?\d+[.,]\d{4,}$/;
export const extraDecimalWithComma = /[,]\d{4,}/;
export const extraDecimalWithDot = /[.]\d{4,}/;
export const decimalWithoutInteger = /\.\d{1,2}$/;
export const decimalWithDotOrComa = /^\d+[.,]\d{2}$/;
export const amountFormat = /^-?(\d{1,3}(\.\d{3})*),\d{1,3}$/;
export const contentWithDecimalOrDotOrComa = /^\d+(?:[.,]\d+)*$/;
export const decimalWithOnlyDotsNotComma = /^\d+(?:\.\d{3})*\.\d{2}$/;
