import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { Form, Input, Select } from 'antd';
import { ITenant } from '../../../interfaces';
import { ITaxType } from '../../../interfaces/Tenant';
import useTaxTypeService from '../../../hooks/useTaxTypeService';
import { notificationContext } from '../../../contexts/NotificationContext';
import { CustomMessage } from '../../../hooks';
import { FormInstance } from 'antd/es/form';
import './BillingInfo.less';

interface Props {
  tenant: ITenant | undefined;
  form: FormInstance;
}

const BillingInfo = (props: Props) => {
  const { tenant, form } = props;

  const { t } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const selectedTaxType = Form.useWatch('tax_type_id', form);

  const { getTaxTypes } = useTaxTypeService();

  const [taxTypesCombo, setTaxTypesCombo] = useState<ITaxType[]>([]);
  const [taxNumber, setTaxNumber] = useState<string | undefined>();

  const initializeData = async () => {
    try {
      const taxTypesResponse = await getTaxTypes();
      setTaxTypesCombo(taxTypesResponse);
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'tenantPage.taxTypes.1',
        msj: getErrorMessage(error),
      });
    }
  };

  useEffect(() => {
    initializeData();
  }, []);

  return (
    <div className="billing-info">
      <Form.Item
        name="billing_name"
        label={`${t('entity.billing_name')} ${t('action.optional')}`}
        className="billing-info__form-item"
      >
        <Input
          id="billing_name"
          placeholder={`${t('action.input.enter')} ${t(
            'entity.billing_name',
          ).toLocaleLowerCase()}`}
          value={tenant?.billing_name}
        />
      </Form.Item>
      <Form.Item
        name="address"
        label={`${t('entity.address')} ${t('action.optional')}`}
        className="billing-info__form-item"
      >
        <Input
          placeholder={`${t('action.input.enter')} ${t(
            'entity.address',
          ).toLocaleLowerCase()}`}
        />
      </Form.Item>

      <Form.Item
        name="tax_type_id"
        label={`${t('entity.tax_type')} ${t('action.optional')}`}
        className="billing-info__form-item"
      >
        <Select
          options={taxTypesCombo.map((taxType) => ({
            label: t(taxType.translation_key as never, {
              defaultValue: taxType.name || '-',
            }),
            value: taxType.id,
          }))}
          getPopupContainer={(node) => node.parentNode}
          allowClear
          placeholder={`${t('action.input.select')} ${t(
            'entity.type',
          ).toLocaleLowerCase()}`}
          showSearch
          filterOption={(inputValue: string, option: any) =>
            option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
          onChange={(value) => {
            if (!value) {
              form.setFieldValue('tax_number', undefined);
            }
            setTaxNumber(value);
          }}
        />
      </Form.Item>

      <Form.Item
        name="tax_number"
        label={`${t('entity.tax_number')} ${
          !selectedTaxType ? t('action.optional') : ''
        }`}
        rules={[
          {
            validator: (_, value: string) => {
              if (!taxNumber) return Promise.resolve();
              const {
                regular_expression: regex,
                error_key,
                error_message,
              } = taxTypesCombo.find(
                (type) => type.id === Number(selectedTaxType),
              )!;

              if (!RegExp(regex).test(value)) {
                return Promise.reject(t(error_key as never) || error_message);
              }
              return Promise.resolve();
            },
          },
          {
            required: !!selectedTaxType,
            message: t('tenantEditPage.taxNumberRequired'),
          },
        ]}
        className="billing-info__form-item"
      >
        <Input
          disabled={!selectedTaxType}
          placeholder={`${t('action.input.enter')} ${t(
            'entity.tax_number',
          ).toLocaleLowerCase()}`}
        />
      </Form.Item>
    </div>
  );
};

export default BillingInfo;
