import { useContext, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { ContextApp } from '../../contexts/ContextApp';
import './IntegrationByTenant.less';
import { PageLoading } from '@ant-design/pro-layout';
import { IIntegration, IIntegrationStatus } from '../../interfaces/Integration';
import GraphqlService from '../../services/graphql/GraphqlService';

export const IntegrationByTenant = (props: {
  integrations: IIntegration[];
  loading: boolean;
  changeSelect: (
    integrationStatusId: number,
    integration: IIntegration,
  ) => void;
}) => {
  const { t } = useContext(ContextApp);
  const { integrations, loading, changeSelect } = props;
  const { customRequest, Query } = GraphqlService();
  const [integrationStatus, setIntegrationStatus] = useState<
    IIntegrationStatus[]
  >([]);
  const [currentLoading, setCurrentLoading] = useState<boolean>(false);

  const getIntegrationStatus = async () => {
    try {
      setCurrentLoading(false);
      const data: IIntegrationStatus[] = await customRequest({
        query: Query.integrationStatus,
      });
      setIntegrationStatus(data);
      setCurrentLoading(true);
    } catch (error) {
      console.error(`getIntegratinStatus - error: `, error);
    }
  };

  useEffect(() => {
    getIntegrationStatus();
  }, []);

  return loading && currentLoading && !integrations ? (
    <PageLoading />
  ) : (
    <>
      <div className="data-validation__header">
        <h4 className="data-validation__header__title">
          {t('tenantEditAdmin.Integration.title')}
        </h4>
      </div>

      {integrations.map((currentIntegration) => (
        <Form.Item
          key={currentIntegration.id}
          name={`integration_status_id_${currentIntegration.id}`}
          label={`${currentIntegration.name}`}
          className="integration_status_id__form-item"
        >
          <Select
            id={`integration_status_id_${currentIntegration.id}`}
            options={integrationStatus.map((state) => ({
              label: state.name,
              value: state.id,
            }))}
            defaultValue={currentIntegration.integration_status_id}
            style={{ width: '50%' }}
            placeholder={`${t('action.input.select')} ${t(
              'entity.status',
            ).toLocaleLowerCase()}`}
            onChange={(value) => changeSelect(value, currentIntegration)}
          />
        </Form.Item>
      ))}
    </>
  );
};
