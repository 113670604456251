import { IReceiptType } from '../interfaces/Receipt';
import GraphqlService from '../services/graphql/GraphqlService';

const useReceiptService = () => {
  const { Query, customRequest } = GraphqlService();

  const getReceiptTypes = async (params: {
    tenant_id: number;
    selectable?: boolean;
    active?: boolean;
  }): Promise<IReceiptType[]> => {
    const { tenant_id, selectable = true, active = true } = params;
    return customRequest({
      query: Query.receipTypes,
      variables: {
        filter: {
          tenant_id,
          selectable,
          active,
        },
      },
    });
  };

  const getReceiptHeader = async (receipt_id: number) => {
    return customRequest({
      query: Query.receiptHeader,
      variables: {
        id: receipt_id,
      },
    });
  };

  return {
    getReceiptTypes,
    getReceiptHeader,
  };
};

export default useReceiptService;
