export const RECEIPT_QUOTA_TENANT = `
query receiptQuotaTenant($tenant_id: Int!) {
  receiptQuotaTenant(tenant_id: $tenant_id) {
    ... on ReceiptsQuotaTenant {
      receipts_quota
      quota_expiration_date
    }
    
    ... on ResultError {
    	status_code
    	message
    }
  }
}
`;
